<template>
	<div class="evaluationReportView" id="evaluationReportView">
		<div>
			<el-button style="position: fixed; right: 80px" type="text" icon="el-icon-download" @click="handleDownLoad" :loading="isLoading"
				>下载报告
			</el-button>
			<el-button style="position: fixed; right: 20px" type="text" icon="el-icon-close" @click="handleClose">关闭 </el-button>
		</div>
		<div class="reportWrap">
			<div class="reportContent" id="reportContent">
				<div class="contentTitle">
					<svg-icon class="logo_title" icon-class="logo_blue"></svg-icon>
					<div class="text_title">四四五</div>
					<div class="text_right">领军人才评估</div>
				</div>
				<div class="contentHeader">
					<div class="text_title">《评估报告》</div>
					<div class="text_right">ASSESSMENT REPORT</div>
				</div>
				<div class="wrap_card">
					<div class="card_header">
						<div class="text_box">
							<span class="userName">{{ userInfo.userName }}</span>

							<span style="display: inline-flex; flex-direction: column; margin-left: 20px">
								<span class="userJob">{{ userInfo.company }}</span>
								<span class="userJob">{{ `${userInfo.officeName}/${getLabelByKey('initialPosition') || ''}` }}</span>
							</span>

							<!-- <div style="white-space: nowrap">
								整体评价：<span class="remark">{{ detailInfo.remarks }}</span>
							</div> synthesizeAppraise -->
						</div>
					</div>
					<div class="card_content" v-if="detailInfo.evaluationType === '0' && detailInfo.initialPosition !== '4'">
						<div class="content_item" v-for="item in overallList" :key="item.label">
							<span>{{ item.label }}</span>
							<span class="item_val">{{ item.value || '-' }}</span>
							<div style="width: 140px"><img style="width: 100%" :src="item.img" /></div>
						</div>
					</div>
				</div>
				<div
					class="wrap_card"
					style="margin-top: 20px; background-color: rgba(20, 128, 166, 0.4); overflow: hidden"
					v-if="isCompetencyAssessment && detailInfo.initialPosition !== '4'"
				>
					<div class="card_header_title">四层管理站位</div>
					<div class="card_content_item">
						<div class="item_inline">
							<span
								>岗位层级：<span style="color: #f1d790; font-weight: bold">{{ getLabelByKey('initialPosition') }}</span></span
							>
							<span
								>实际站位：<span style="color: #f1d790; font-weight: bold">{{ getLabelByKey('actualPosition') }}</span></span
							>
							<span
								>管理站位评估：<span style="color: #f1d790; font-weight: bold">{{ getLabelByKey('positionMatch') }}</span></span
							>
						</div>
						<div style="display: inline-block; letter-spacing: 1px; line-height: 26px">
							<span style="color: #f1d790; font-weight: bold">评价：</span>
							<span v-html="detailInfo.positionComment"></span>
						</div>
					</div>
				</div>
				<div
					class="wrap_card"
					style="margin-top: 20px; background-color: rgba(20, 128, 166, 0.4); overflow: hidden"
					v-if="isCompetencyAssessment && detailInfo.initialPosition !== '4'"
				>
					<div class="card_header_title">四项管理能力</div>
					<div class="card_content_item">
						<div class="item_manage_item">
							<div style="width: 200px">
								<div style="margin-top: 10px">
									<span class="item_class">【战略能力】</span>
									<div class="item_text">{{ getLabelByKey('strategyMatch') }}</div>
								</div>
								<div style="margin-top: 40px">
									<span class="item_class">【体系构建能力】</span>
									<div class="item_text">{{ getLabelByKey('flowMatch') }}</div>
								</div>
							</div>
							<div style="width: 280px"><img style="width: 100%" src="@/assets/report/reportyl_4个图标.png" /></div>
							<div style="width: 200px">
								<div style="margin-top: 10px">
									<span class="item_class">【变革能力】</span>
									<div class="item_text">{{ getLabelByKey('changeMatch') }}</div>
								</div>
								<div style="margin-top: 40px">
									<span class="item_class">【人才建设能力】</span>
									<div class="item_text">{{ getLabelByKey('advocateMatch') }}</div>
								</div>
							</div>
						</div>
						<div style="display: inline-block; letter-spacing: 1px; line-height: 26px">
							<span style="color: #f1d790; font-weight: bold">评价：</span>
							<span v-html="detailInfo.strategyComment"></span>
							<span v-html="detailInfo.changeComment"></span>
							<span v-html="detailInfo.flowComment"></span>
							<span v-html="detailInfo.advocateComment"></span>
						</div>
					</div>
				</div>
				<div class="wrap_card" style="margin-top: 20px; background-color: rgba(20, 128, 166, 0.4); overflow: hidden" v-if="isQuality">
					<div class="card_header_title">领军人才五项素质</div>
					<div class="card_content_item">
						<div class="quality_five">
							<div class="quality_five_radar">
								<Radar :data="radarData" :indicator="indicator" :splitNumber="3" />
							</div>
							<div class="quality_five_list">
								五项素质分布：
								<ul>
									<li>
										主动性<span>{{ detailInfo.initiative }}级</span>
									</li>
									<li>
										概念思维 <span>{{ detailInfo.conceptThinking }}级</span>
									</li>
									<li>
										影响力<span>{{ detailInfo.influence }}级</span>
									</li>
									<li>
										成就导向<span>{{ detailInfo.achievementGuide }}级</span>
									</li>
									<li>
										坚韧性<span>{{ detailInfo.hardiness }}级</span>
									</li>
								</ul>
							</div>
						</div>
						<div style="display: inline-block; letter-spacing: 1px; line-height: 26px">
							<span style="color: #f1d790; font-weight: bold">评价：</span>
							<span v-html="detailInfo.initiativeComment"></span>
							<span v-html="detailInfo.conceptComment"></span>
							<span v-html="detailInfo.influenceComment"></span>
							<span v-html="detailInfo.guideComment"></span>
							<span v-html="detailInfo.hardinessComment"></span>
						</div>
					</div>
				</div>
				<div class="wrap_card" style="margin-top: 20px; background-color: rgba(20, 128, 166, 0.4); overflow: hidden">
					<div class="card_header_title">总体建议</div>
					<div class="card_content_item">
						<span v-html="detailInfo.rankNote" v-if="isCompetencyAssessment"></span>
						<span v-html="detailInfo.synthesizeAppraise" v-if="isQuality"></span>
					</div>
				</div>
				<div class="report_footer">
					<div>评价报告由深圳百森咨询有限公司官方认证，深圳百森咨询保留本报告的最终解释权。</div>
					<div>报告日期: {{ nowTime }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getUrlParams, html2Image } from '@/utils/util';
import Radar from '@/views/components/components-radar-chart';
import moment from 'moment';

export default {
	name: 'EvaluationReportView',
	components: { Radar },
	data() {
		return {
			detailInfo: {},
			userInfo: {},
			indicator: [
				{ name: '主动性', max: 3 },
				{ name: '概念思维', max: 3 },
				{ name: '影响力', max: 3 },
				{ name: '成就导向', max: 3 },
				{ name: '坚韧性', max: 3 }
			],
			isLoading: false
		};
	},
	computed: {
		query() {
			let query = {};

			if (this.$route.query.params) {
				query = JSON.parse(getUrlParams(this.$route.query.params));
			}
			return query;
		},
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		getLabelByKey() {
			return (key) => {
				let groupId = '';

				const val = this.detailInfo[key];
				// detailInfo.initialPosition,

				switch (key) {
					case 'initialPosition':
					case 'actualPosition':
						groupId = 'rankName';
						break;
					case 'positionMatch':
						groupId = 'positionMatch';
						break;
					case 'strategyMatch':
					case 'flowMatch':
					case 'changeMatch':
					case 'advocateMatch':
						groupId = 'abilityLevel';
						break;

					default:
						break;
				}
				const str = this.getOptsById(groupId).find(({ dictId }) => dictId === val)?.dictName || '-';

				return str;
			};
		},
		overallList() {
			const {
				strategy,
				changeExpert,
				flowExpert,
				employeeAdvocate,
				initiative,
				conceptThinking,
				influence,
				achievementGuide,
				hardiness,
				positionMatch, // 站位匹配
				rankLevel, // 胜任度
				talentType // 人才类型
			} = this.detailInfo;

			let zwUrl = '';

			let srUrl = '';

			let rcUrl = '';

			if (positionMatch === '1') {
				zwUrl = require('@/assets/report/小标标_0008_站位正确.png');
			} else {
				zwUrl = require('@/assets/report/小标标_0007_站位不正确.png');
			}
			switch (rankLevel) {
				case '1':
					srUrl = require('@/assets/report/小标标_0004_完全胜任.png');
					break;
				case '2':
					srUrl = require('@/assets/report/小标标_0005_基本胜任.png');
					break;
				case '3':
					srUrl = require('@/assets/report/小标标_0006_暂不胜任.png');
					break;
				default:
					srUrl = require('@/assets/report/reportyl_非人才.png');
					break;
			}
			switch (talentType) {
				case '1':
					rcUrl = require('@/assets/report/小标标_0003_图层-4.png');
					break;
				case '2':
					rcUrl = require('@/assets/report/小标标_0002_守成型人才.png');
					break;
				case '3':
					rcUrl = require('@/assets/report/小标标_0001_图层-8.png');
					break;
				default:
					rcUrl = require('@/assets/report/reportyl_非人才.png');
					break;
			}
			return [
				{
					label: '【管理站位评估】',
					value: this.getLabelByKey('initialPosition'),
					img: zwUrl
				},
				{
					label: '【管理能力评估】',
					value: `${strategy || ''}${changeExpert || ''}${flowExpert || ''}${employeeAdvocate || ''}`,
					img: srUrl
				},
				{
					label: '【五项素质评估】',
					value: `${initiative || ''}${conceptThinking || ''}${influence || ''}${achievementGuide || ''}${hardiness || ''}`,
					img: rcUrl
				}
			];
		},
		radarData() {
			const { initiative, conceptThinking, influence, achievementGuide, hardiness } = this.detailInfo;

			return [
				{
					value: [Number(initiative), Number(conceptThinking), Number(influence), Number(achievementGuide), Number(hardiness)],
					areaStyle: {
						color: this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [
							{
								color: 'rgba(10, 120, 199, 0.3)',
								offset: 0
							},
							{
								color: 'rgba(10, 120, 199, 0.9)',
								offset: 1
							}
						])
					}
				}
			];
		},
		nowTime() {
			return moment(new Date()).format('YYYY年MM月DD日  HH:mm');
		},
		// 胜任度
		isCompetencyAssessment() {
			return this.detailInfo.evaluationType !== '2';
		},
		// 潜力素质评估
		isQuality() {
			return this.detailInfo.evaluationType !== '1';
		}
	},

	mounted() {
		this.getReportDetail();
	},

	methods: {
		getReportDetail() {
			this.$axios.post('/v1/internal/talent/getReportDetailById', { request: { id: this.query.id } }).then((res) => {
				if (res.code) {
					return;
				}
				this.detailInfo = res;
				this.userInfo = res.userInfo;
			});
		},
		handleDownLoad() {
			// 打印 下载
			this.$nextTick(() => {
				this.isLoading = true;
				html2Image(
					'reportContent',
					`${this.userInfo.userName}-评估报告`,
					() => {
						this.isLoading = false;
					},
					2 // 质量
				);
			});
		},
		handleClose() {
			window.close();
		}
	}
};
</script>

<style lang="scss" scoped>
.evaluationReportView {
	.titlebox {
		margin-bottom: 0;
	}
	.reportWrap {
		margin: 0 auto;
		width: 1200px;
		padding: 0 60px;
		user-select: none;
		pointer-events: none;
		.reportContent {
			background-color: #0f2147;
			color: #fff;
			.contentTitle {
				margin: 30px 0;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				.text_title {
					display: inline-flex;
					font-weight: bolder;
					font-size: 60px;
					margin-left: 20px;
				}
				.text_right {
					padding: 20px 0 0 10px;
					font-weight: bolder;
					font-size: 38px;
					font-family: Pingfang;
				}
				.logo_title {
					width: 100px;
					height: 100px;
				}
			}
			.contentHeader {
				margin: 30px 0;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				flex-direction: column;
				font-family: fangsong;
				.text_title {
					display: inline-flex;
					font-weight: bolder;
					font-size: 42px;
					margin-left: 20px;
					letter-spacing: 0.2em;
				}
				.text_right {
					padding: 20px 0 0 10px;
					font-size: 30px;
					letter-spacing: 0.2em;
				}
			}
			.wrap_card {
				margin: 0 120px;
				background-color: rgba(20, 128, 166, 0.6);
				border-radius: 6px;
				.card_header {
					font-family: songti;
					display: inline-flex;
					justify-content: center;
					width: 100%;
					margin: 30px 0;
					.text_box {
						width: 478px;
						display: inline-flex;
						flex-direction: column;
						font-size: 30px;
						letter-spacing: 4px;
						line-height: 40px;
						text-align: center;
						flex-direction: row;
						justify-content: center;
						white-space: nowrap;
						.userName {
							font-size: 44px;
							margin: 20px 0;
							font-weight: bolder;
							margin-right: 20px;
							display: inline-flex;
							align-items: center;
						}
						.userJob {
							white-space: pre-wrap;
							min-width: 360px;
						}
						.remark {
							font-family: kaiti;
							font-size: 36px;
							font-weight: bold;
							color: #fcde8f;
							text-shadow: 1px 2px 4px #000;
						}
					}
				}
				.card_content {
					margin: 20px 60px;
					margin-top: 0;
					display: inline-flex;
					width: calc(100% - 120px);
					justify-content: space-between;
					border-top: 2px solid #5291ae;
					font-size: 28px;
					padding-top: 40px;
					.content_item {
						display: inline-flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						font-size: 26px;
						font-weight: normal;
						font-family: fangsong;
						.item_val {
							font-size: 30px;
							font-weight: bolder;
							margin: 20px 0;
						}
					}
				}
				.card_header_title {
					display: inline-block;
					font-size: 26px;
					font-weight: bold;
					background-color: rgba(20, 128, 166, 0.6);
					margin: 30px 0;
					padding: 10px 30px 10px 60px;
				}
				.card_content_item {
					font-family: fangsong;
					font-size: 22px;
					display: inline-flex;
					flex-direction: column;
					width: calc(100% - 120px);
					margin: 0 60px 60px 60px;
					.item_inline {
						display: inline-flex;
						justify-content: space-between;
						margin-bottom: 36px;
					}
					.item_manage_item {
						display: inline-flex;
						width: 100%;
						justify-content: space-between;
						margin-bottom: 20px;
						.item_class {
							font-size: 26px;
							font-weight: bold;
							margin-bottom: 10px;
							white-space: nowrap;
						}
						.item_text {
							font-family: kaiti;
							margin-top: 10px;
							// background-color: #ccc;
							color: #edd899;
							font-size: 32px;
							font-weight: bolder;
							padding: 16px 0;
							text-align: center;
						}
					}
					.quality_five {
						// width: 70%;
						display: inline-flex;
						.quality_five_radar {
							width: 70%;
						}
						.quality_five_list {
							width: 30%;
							height: 300px;
							border: 2px solid #7193aa;
							font-weight: bold;
							white-space: nowrap;
							padding: 20px 30px;
							line-height: 40px;
							margin-top: 120px;
							ul {
								list-style: disc;
								margin-left: 20px;
								color: #efefef;
								font-weight: inherit;
								li {
									> span {
										float: right;
										color: #f1d790;
										font-weight: bold;
									}
								}
							}
						}
					}
				}
			}
			.report_footer {
				padding: 20px 60px;
				text-align: center;
				font-size: 14px;
				color: #999;
			}
		}
	}
}
</style>
